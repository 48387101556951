<template>
  <div class="pr-3 pl-3">
    <h2 class="">{{ title }}</h2>

    <div class="checkLabelArea">
      <v-checkbox
        hide-details
        v-if="order.status === '50'"
        @click="order.isOrigin = !order.isOrigin"
      >
        <template v-slot:label>
          <span class="checkboxLabel">기존 데이터만 보기</span>
        </template>
      </v-checkbox>
    </div>

    <div class="orderRequestLeft">
      <div class="text-bold releaseGridBorderLeft">ORDER NO</div>
      <div class="text-bold requestOrderGridBorderLeft">
        {{ order.order ? order.order : "" }}
      </div>
      <!-- 납기일 -->
      <div class="text-bold releaseGridBorderLeft">BUYER ORDER NO</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.buyer_order !== origin.buyer_order"
          class="originTextRight"
        >
          {{ origin.buyer_order ? origin.buyer_order : "" }}
        </div>
        <div
          :class="
            order.buyer_order !== origin.buyer_order && origin.buyer_order
              ? 'originText'
              : ''
          "
        >
          {{ order.buyer_order ? order.buyer_order : "" }}
        </div>
      </div>
      <!-- leftForm 구매처 -->
      <div class="text-bold releaseGridBorderLeft">구매처</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.client !== origin.client"
          class="originTextRight"
        >
          {{ origin.client ? origin.client : "" }}
        </div>
        <div
          :class="
            order.client !== origin.client && origin.client
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.client ? order.client : "" }}
        </div>
      </div>
      <!-- leftForm 오더 유형 -->
      <div class="text-bold releaseGridBorderLeft">오더 유형</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.order_kind !== origin.order_kind"
          class="originTextRight"
        >
          {{ origin.order_kind ? origin.order_kind : "" }}
        </div>
        <div
          :class="
            origin.order_kind && order.order_kind !== origin.order_kind
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.order_kind }}
        </div>
      </div>
      <!-- leftForm 복종 -->
      <div class="text-bold releaseGridBorderLeft">복종</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.ctype !== origin.ctype"
          class="originTextRight"
        >
          {{ origin.ctype && origin.ctype ? origin.ctype : "선택안함" }}
        </div>
        <div
          :class="
            origin.ctype && order.ctype !== origin.ctype
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.ctype && order.ctype ? order.ctype : "선택안함" }}
        </div>
      </div>

      <!-- leftForm 성별 -->
      <div class="text-bold releaseGridBorderLeft">성별</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.gender !== origin.gender"
          class="originTextRight"
        >
          {{ order.gender }}
        </div>
        <div
          :class="
            origin.gender && order.gender !== origin.gender
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.gender }}
        </div>
      </div>

      <!-- leftForm 아이템(자사) -->
      <div class="text-bold releaseGridBorderLeft">아이템(자사)</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.item !== origin.item"
          class="originTextRight"
        >
          {{ origin.item ? origin.item : "" }}
        </div>
        <div
          :class="
            origin.item && order.item !== origin.item
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.item ? order.item : "" }}
        </div>
      </div>

      <!-- leftForm 생산처 -->
      <div class="text-bold releaseGridBorderLeft">생산처</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.production !== origin.production"
          class="originTextRight"
        >
          {{ origin.production ? origin.production : "" }}
        </div>
        <div
          :class="
            origin.production && order.production !== origin.production
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.production ? order.production : "" }}
        </div>
      </div>

      <!-- leftForm 아이템(생산처) -->
      <div class="text-bold releaseGridBorderLeft">아이템(생산처)</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="
            order.isOrigin && order.productionItem !== origin.productionItem
          "
          class="originTextRight"
        >
          {{ origin.productionItem ? origin.productionItem : "" }}
        </div>
        <div
          :class="
            origin.productionItem &&
            order.productionItem !== origin.productionItem
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.productionItem ? order.productionItem : "" }}
        </div>
      </div>

      <!-- leftForm 통화 단위-->
      <div class="text-bold releaseGridBorderLeft">담당자</div>
      <div class="text-bold requestOrderGridBorderLeft">
        {{ order.liaison ? order.liaison : "" }}
      </div>

      <!-- leftForm 매입 구분 -->
      <div class="text-bold releaseGridBorderLeft">매입 구분</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="
            order.isOrigin &&
            order.purchase_classification !== origin.purchase_classification
          "
          class="originTextRight"
        >
          {{
            origin.purchase_classification ? origin.purchase_classification : ""
          }}
        </div>
        <div
          :class="
            origin.purchase_classification &&
            order.purchase_classification !== origin.purchase_classification
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.purchase_classification }}
        </div>
      </div>
      <div class="text-bold releaseGridBorderLeft">매출 구분</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="
            order.isOrigin &&
            order.sales_classification !== origin.sales_classification
          "
          class="originTextRight"
        >
          {{ origin.sales_classification ? origin.sales_classification : "" }}
        </div>
        <div
          :class="
            origin.sales_classification &&
            order.sales_classification !== origin.sales_classification
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.purchase_classification }}
        </div>
      </div>
      <div class="text-bold releaseGridBorderLeft">통화 단위</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="order.isOrigin && order.eschamge_sign !== origin.eschamge_sign"
          class="originTextRight"
        >
          {{ origin.eschamge_sign ? origin.eschamge_sign : "" }}
        </div>
        <div
          :class="
            origin.eschamge_sign && order.eschamge_sign !== origin.eschamge_sign
              ? 'originText red--text'
              : ''
          "
        >
          {{ order.eschamge_sign }}
        </div>
      </div>
      <div class="text-bold releaseGridBorderLeft">매출단가</div>
      <div class="text-bold requestOrderGridBorderLeft">
        <div
          v-if="
            order.isOrigin && order.sales_unit_price !== origin.sales_unit_price
          "
          class="originTextRight"
        >
          {{
            origin.sales_unit_price
              ? `${origin.eschamge_sign}${origin.sales_unit_price}`
              : "" | makeComma
          }}
        </div>
        <div
          :class="
            origin.sales_unit_price &&
            order.sales_unit_price !== origin.sales_unit_price
              ? 'originText red--text'
              : ''
          "
        >
          {{
            `${
              order.sales_unit_price
                ? `${order.eschamge_sign}${order.sales_unit_price}`
                : ""
            }` | makeComma
          }}
        </div>
      </div>
      <div class="text-bold releaseGridBorderLeft requestBorderBottom">
        매출 Surcharge
      </div>
      <div class="text-bold requestOrderGridBorderLeft requestBorderBottom">
        <div
          v-if="
            order.isOrigin && order.sales_surcharge !== origin.sales_surcharge
          "
          class="originTextRight"
        >
          {{
            origin.sales_surcharge
              ? `${origin.eschamge_sign}${origin.sales_surcharge}`
              : "" | makeComma
          }}
        </div>
        <div
          :class="
            origin.sales_surcharge &&
            order.sales_surcharge !== origin.sales_surcharge
              ? 'originText red--text'
              : ''
          "
        >
          {{
            order.sales_surcharge
              ? `${order.eschamge_sign}${order.sales_surcharge}`
              : "" | makeComma
          }}
        </div>
      </div>
      <div class="text-bold releaseGridBorderLeft requestBorderBottom">
        선수금
      </div>
      <div class="text-bold requestOrderGridBorderLeft requestBorderBottom">
        <div
          v-if="
            order.isOrigin &&
            origin.advance_pay &&
            order.advance_pay !== origin.advance_pay
          "
          class="originTextRight"
        >
          {{
            origin.advance_pay
              ? `${origin.eschamge_sign}${origin.advance_pay}`
              : "" | makeComma
          }}
        </div>
        <div
          :class="
            origin.advance_pay && order.advance_pay !== origin.advance_pay
              ? 'originText red--text'
              : ''
          "
        >
          {{
            order.advance_pay
              ? `${order.eschamge_sign}${order.advance_pay}`
              : "" | makeComma
          }}
        </div>
      </div>
      <div
        class="text-bold requestOrderRemark"
        style="border-bottom: thin solid rgba(0, 0, 0, 1)"
      >
        <v-textarea
          v-model="order.remark"
          rows="7"
          dense
          label="비고"
          hide-details
          class="lefted-input pt-1"
          :class="order.remark !== origin.remark ? 'red--text' : ''"
          disabled
          no-resize
        />
      </div>
    </div>
    <div class="orderRequestBottomGrid">
      <div class="text-bold orderRequestStyle">STYLE LIST</div>

      <div class="text-bold orderRequestColor">COLOR LIST</div>
      <div class="requestBottomBodyGrid">
        <div>
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
            ref="scrollBar"
            defer
            style="border-bottom: thin solid black"
          >
            <div style="width: 100%; max-height: 20vh">
              <div>
                <template v-if="order.style_list && order.style_list.length">
                  <div
                    v-for="(style, i) in order.style_list"
                    :key="i"
                    class="orderRequestList"
                  >
                    <div class="requestFlexBody">
                      <div
                        v-if="
                          order.isOrigin &&
                          origin.style_list[i] !== style &&
                          origin.style_list[i]
                        "
                        class="originTextRight"
                      >
                        {{ origin.style_list[i] }}
                      </div>
                      <div
                        :class="
                          origin.style_list[i] !== style
                            ? 'originText red--text'
                            : ''
                        "
                      >
                        {{ style }}
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="orderRequestList"></div>
                </template>
              </div>
            </div>
          </overlay-scrollbars>
        </div>
      </div>
      <div class="requestBottomBodyGrid">
        <div>
          <overlay-scrollbars
            :options="{
              overflowBehavior: {
                x: 'hidden',
              },
            }"
            ref="scrollBar"
            defer
          >
            <div style="width: 100%; min-height: 20vh; max-height: 20vh">
              <template v-if="order.color_list && order.color_list.length">
                <div class="requestColorGrid orderRequestList">
                  <div class="requestColorBody font-weight-bold"></div>
                  <div
                    class="
                      requestColorBody
                      font-weight-bold
                      bodyLeftBlackBorder
                    "
                  >
                    컬러명
                  </div>
                  <div
                    class="
                      bodyLeftBlackBorder
                      requestColorBody
                      font-weight-bold
                    "
                  >
                    수량
                  </div>
                  <div
                    class="
                      bodyLeftBlackBorder
                      requestColorBody
                      font-weight-bold
                    "
                  >
                    단가
                  </div>
                  <div
                    class="
                      bodyLeftBlackBorder
                      requestColorBody
                      font-weight-bold
                    "
                  >
                    Surcharge
                  </div>
                </div>
                <div
                  v-for="(color, i) in order.color_list"
                  :key="i"
                  class="orderRequestList"
                >
                  <div class="requestColorGrid">
                    <div class="requestColorBody"></div>
                    <div class="requestColorBody bodyLeftBlackBorder">
                      <div
                        v-if="
                          order.isOrigin &&
                          origin.color_list[i] &&
                          origin.color_list[i].name !== color.name
                        "
                        class="originTextRight"
                      >
                        {{
                          origin.color_list[i] ? origin.color_list[i].name : ""
                        }}
                      </div>
                      <div
                        :class="
                          colorCompare('name', i)
                            ? 'originText red--text  '
                            : ''
                        "
                      >
                        {{ color.name ? color.name : "" }}
                      </div>
                    </div>
                    <div class="requestColorBodyRight">
                      <div
                        v-if="
                          order.isOrigin &&
                          origin.color_list[i] &&
                          origin.color_list[i].quantity !== color.quantity
                        "
                        class="colorListOriginTextRight pr-2"
                      >
                        {{
                          (origin.color_list[i]
                            ? origin.color_list[i].quantity + origin.unit_sign
                            : "") | makeComma
                        }}
                      </div>
                      <div
                        :class="
                          origin.color_list.length &&
                          colorCompare('quantity', i)
                            ? 'colorListOriginText'
                            : ''
                        "
                      >
                        {{
                          color.quantity
                            ? color.quantity + order.unit_sign
                            : "" | makeComma
                        }}
                      </div>
                    </div>
                    <div class="requestColorBodyRight">
                      <div
                        v-if="
                          order.isOrigin &&
                          origin.color_list[i] &&
                          origin.color_list[i].unit_price !== color.unit_price
                        "
                        class="colorListOriginTextRight pr-2"
                      >
                        {{
                          (origin.color_list[i]
                            ? origin.eschamge_sign +
                              origin.color_list[i].unit_price
                            : "") | makeComma
                        }}
                      </div>
                      <div
                        :class="
                          origin.color_list.length &&
                          colorCompare('unit_price', i)
                            ? 'colorListOriginText'
                            : ''
                        "
                      >
                        {{
                          color.unit_price
                            ? order.eschamge_sign + color.unit_price
                            : "" | makeComma
                        }}
                      </div>
                    </div>
                    <div class="requestColorBodyRight">
                      <div
                        v-if="
                          order.isOrigin &&
                          origin.color_list[i] &&
                          origin.color_list[i].surcharge_price !==
                            color.surcharge_price
                        "
                        class="colorListOriginTextRight"
                      >
                        {{
                          (origin.color_list[i]
                            ? origin.eschamge_sign +
                              origin.color_list[i].surcharge_price
                            : "") | makeComma
                        }}
                      </div>
                      <div
                        :class="
                          origin.color_list.length &&
                          colorCompare('surcharge_price', i)
                            ? 'colorListOriginText'
                            : ''
                        "
                      >
                        {{
                          color.surcharge_price
                            ? order.eschamge_sign + color.surcharge_price
                            : "" | makeComma
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="colorTotalGrid">
                  <div
                    style="width: 100%"
                    class="requestColorBody font-weight-bold"
                  >
                    TOTAL
                  </div>
                  <div style="width: 100%" class="requestColorBodyRight">
                    {{ colorTotal }} 개
                  </div>
                  <div style="width: 100%" class="requestColorBodyRight">
                    {{ (quantityTotal + order.unit_sign) | makeComma }}
                  </div>
                  <div style="width: 100%" class="requestColorBodyRight">
                    {{ (order.eschamge_sign + unitTotal) | makeComma }}
                  </div>
                  <div style="width: 100%" class="requestColorBodyRight">
                    {{ (order.eschamge_sign + surchargeTotal) | makeComma }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="orderRequestList">
                  <div class="requestColorGrid"></div>
                </div>
              </template>
            </div>
          </overlay-scrollbars>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      styleHeader: [
        {
          text: "STYLE NO",
          value: "style",
          align: "center",
          width: "40%",
        },
        {
          value: "count",
          align: "center",
          width: "50%",
        },
        { value: "actions", width: "10%", sortable: false },
      ],
      colorHeader: [
        {
          text: "COLOR",
          value: "name",
          align: "center",
          width: "40%",
        },
        {
          text: "수량",
          value: "quantity",
          align: "center",
          width: "30%",
        },
        {
          text: "",
          value: "unitSign",
          align: "center",
          width: "30%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      order: "getRequestForm",
    }),
    heightTable() {
      return `${this.windowHeight - 800}px`;
    },
    totalQuantity() {
      return this.order.color_list.length
        ? this.order.color_list.reduce((a, c) => a + c.quantity, 0)
        : 0;
    },
    title() {
      return `오더(${
        this.order.status === "20"
          ? "승인"
          : this.order.status === "50"
          ? "수정"
          : "취소"
      } 요청) `;
    },

    origin() {
      return this.order.origin
        ? this.order.origin
        : { color_list: [], style_list: [] };
    },
    colorTotal() {
      return this.order.color_list.length;
    },
    quantityTotal() {
      return this.order.color_list.reduce((r, c) => r + c.quantity, 0);
    },
    unitTotal() {
      const colorPrice = this.order.color_list.reduce(
        (r, i) => (i.unit_price ? r + i.unit_price * i.quantity : r),
        0,
      );
      return this.roundsData(colorPrice + this.surchargeTotal);
    },
    surchargeTotal() {
      return this.order.color_list.reduce((r, c) => r + c.surcharge_price, 0);
    },
  },
  methods: {
    colorCompare(type, i) {
      const requestColor =
        this.order.color_list[i] && this.order.color_list[i][type];
      const originColor =
        this.order.origin &&
        this.order.origin.color_list[i] &&
        this.order.origin.color_list[i][type];

      return (originColor !== undefined || requestColor !== undefined) &&
        originColor !== requestColor
        ? true
        : false;
    },
  },
};
</script>

<style></style>
