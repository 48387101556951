import UserRequest from "@/components/pages/requestDialog/UserRequest.vue";
import reqeustList from "@/components/pages/requestDialog/reqeustList.vue";
import requestFormDialog from "@/components/pages/requestDialog/RequestFormDialog.vue";
import centerMenu from "@/components/organisms/SubMenu/centerMenu";
import { mapGetters } from "vuex";
import email_dialog from "@/components/organisms/VDialog/emailDialog";
import cacution_dialog from "@/components/organisms/VDialog/cacutionDialog";
import logo from "@/assets/image/svg/logo.svg";
import accountIcon from "@/assets/image/svg/account_circle.svg";
import notifications from "@/assets/image/svg/notifications.svg";
import printBtn from "@/assets/image/svg/print.svg";
import excelBtn from "@/assets/image/svg/excel.svg";
import filterBtn from "@/assets/image/svg/filter_button.svg";
import { downloadFile } from "@/api/file";
import commitName from "@/components/pages/common/ApiActionName.js";

export default {
  components: {
    printBtn,

    UserRequest,
    email_dialog,
    logo,
    accountIcon,
    notifications,
    excelBtn,
    filterBtn,
    centerMenu,
    reqeustList,
    cacution_dialog,
    requestFormDialog,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      history_uuid: [],
      history_buid: [],
      headerAlertList: [],
      year: new Date(Date.now()).getFullYear(),
      yearList: [],
      selectOrder: { order: "" },
      selectManufactures: {},
      selectRelease: {},
      selectType: "",
      selectRequestForm: {},
      colorEditedItem: [],
      items: [
        {
          value: "오더",
          label: "오더",
        },
        {
          value: "가공의뢰서",
          label: "가공의뢰서",
        },
        {
          value: "출고의뢰서",
          label: "출고의뢰서",
        },
      ],
      printMenu: false,
      alertMenu: false,
      dialog_release_print: false,
      dialog_manufacture_print: false,
      allowExcelPages: [
        "main",
        "order",
        "purchasecalculate",
        "purchasedeadline",
        "salescalculate",
        "salesdeadline",
        "claim",
        "endClaim",
        "purchaseVat",
        "salesVat",
      ],
      alertTabs: 0,
    };
  },
  computed: {
    ...mapGetters({
      orderList: "getOrderList",
      production: "getProductionPrint",
      productionList: "getProductionList",

      totalCount: "getPageTotalCount",
    }),
    size() {
      const size = { xs: "x-small", sm: "small", lg: "", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    printTypeDisabled() {
      return !this.selectOrder.order;
    },
    printSelectDisabled() {
      return !this.selectType;
    },
    printTargetSelectDisabled() {
      return !this.selectType || this.selectType === "오더";
    },
    printBtnDisabled() {
      return this.selectType !== "오더" && !this.selectRequestForm.kind;
    },
    userGrade() {
      return this.$store.state.auth_grade === "5"
        ? "총괄 책임자"
        : this.$store.state.auth_grade >= "3"
        ? "매니저"
        : "일반 계정";
    },
    header() {
      return {
        main: this.$store.state.mainHeader,
        order: this.$store.state.orderHeader,
        production: this.$store.state.productionHeader,
        purchasecalculate: this.$store.state.purCacheader,
        purchasedeadline: this.$store.state.purDedheader,
        salescalculate: this.$store.state.salesCacheader,
        salesdeadline: this.$store.state.saelsDedheader,
        purchaseVat: this.$store.state.receiveHeader,
        salesVat: this.$store.state.saelsreceiveHeader,
        claim: this.$store.state.claimHeader,
        endClaim: this.$store.state.endClaimHeader,
        stock: this.$store.state.stockHeader,
      };
    },
    routeName() {
      return this.$route.name;
    },

    setParam() {
      const names = commitName[this.routeName];
      const status =
        this.routeName === "order" && this.$store.state.auth_grade >= 2
          ? names.managerStatus
          : names.status;
      const type = this.setFilterType;
      return {
        multi: JSON.stringify([]),
        field: "",
        sort: 1,
        status: status,
        pageType: names.pageType,
        type,
        isEndCase: names.isEndCase,
        kind: names.kind,
      };
    },

    setFilterType() {
      return this.routeName === "stock"
        ? this.$store.state.stockSelected
        : this.routeName === "purchaseVat"
        ? this.$store.state.vatSelected
        : this.routeName === "salesVat"
        ? this.$store.state.vatSalesSelected
        : commitName[this.routeName].type;
    },
  },

  methods: {
    async pageMove(page) {
      this.alertMenu = false;

      if (page.route) {
        if (this.$router.history.current.name !== page.route)
          this.$store.dispatch("FILTER_SELECT_MOVE", {
            target: page.route,
            name: page.name,
          });
      } else {
        this.$store.commit("setAPILoading", true);
        await this.$store.dispatch("SELECT_APPROVAL_LIST");
        this.$store.dispatch("SET_REQUEST_DATA", page.name);
        this.$store.commit("setRequestDialog");
        this.$store.commit("setAPILoading", false);
      }
    },

    async approBtnClinck() {
      if (this.$store.state.requestList.length) return;
      await this.$store.dispatch("SELECT_APPROVAL_LIST");
    },

    async requestDoc() {
      await this.$store.dispatch("SELECT_APPROVAL_LIST");
      if (!this.$store.state.requestList.length)
        this.$store.commit("setSnackBar", "요청중인 문서가 없습니다.");
      else this.$store.commit("setUserRequestDialog");
    },

    // Start user
    logout() {
      this.$store.dispatch("LOGOUT");
      this.$router.push({
        name: "login",
      });
    },
    loginHistory(index) {
      this.$store.dispatch(
        "SET_LOGIN_BY_HISTORY",
        this.$store.state.history_uuid.indexOf(this.history_uuid[index]),
      );
      this.$router.push({ path: "/login" });
    },
    loadHistory() {
      this.history_uuid = this.$store.state.history_uuid.slice();
      this.history_company = this.$store.state.history_company.slice();
      const temp = this.history_uuid.indexOf(this.$store.state.auth_uuid);
      if (temp !== -1) {
        this.history_uuid.splice(temp, 1);
        this.history_company.splice(temp, 1);
      }
    },

    // End User

    setPrint() {
      if (this.$route.name === "order") this.setOrderPrint();
      else this.setProductionPrint();
    },

    async setOrderPrint() {
      if (!this.$store.state.orderForm._id) {
        this.$store.commit("setSnackBar", "오더를 선택해주세요");
        return;
      }
      await this.$store.dispatch("SELECT_ORDER", this.$store.state.orderForm);
      this.openPrintDialog("order", this.$store.state.selectOrder);
      this.$store.commit("setSelectOrder");
    },

    setProductionPrint() {
      if (this.production.dialog)
        this.openPrintDialog(
          this.production.kind === 7
            ? "가공의뢰서"
            : this.production.kind === 10
            ? "검사의뢰서"
            : "출고의뢰서",
          this.productionList.find(
            (x) =>
              x.id === this.production.id && x.kind === this.production.kind,
          ),
        );
      else
        this.$store.commit(
          "setSnackBar",
          "승인된 출고 또는 가공 의뢰서를 선택해주세요",
        );
    },

    openPrintDialog(type, data) {
      this.$store.dispatch("PRINT_DIALOG_OPEN", {
        type,
        data,
      });
    },

    async setHeaderAlert(e) {
      if (this.$store.state.auth_token) {
        this.$store.dispatch("SELECT_APPROVAL_LIST");

        // this.$store.dispatch("GET_REQUEST_APPROVAL");
        this.changeReqeustForm(this.$store.state.auth_grade >= 3 ? 1 : 0);
      }
      // await this.$store.dispatch("GET_LOGO_ID");
      if (this.$store.state.logoImg) this.$store.dispatch("GET_LOGO_IMG");
    },

    getPrintList(e) {
      if (e) e.preventDefault();
      const promises = [];
      promises.push(this.$store.dispatch("SET_ORDERLIST_DATA"));
      promises.push(this.$store.dispatch("SET_CLIENTLIST"));
      promises.push(this.$store.dispatch("SET_USERLIST"));
      promises.push(this.$store.dispatch("SET_ITEMLIST"));
      promises.push(this.$store.dispatch("SET_CTYPELIST"));
      Promise.all(promises).then(() => {
        this.$store.dispatch("SET_API_LOADING", false);
      });
    },
    exportExcel() {
      if (this.$route.name === "order")
        this.$store.dispatch("ORDER_DOWNLOAD_EXCEL");
      if (this.$route.name === "main")
        this.$store.dispatch("MAIN_DOWNLOAD_EXCEL");
      if (this.$route.name === "purchasedeadline")
        this.$store.dispatch("PURCHASE_DOWNLOAD_EXCEL");
      if (this.$route.name === "salesdeadline")
        this.$store.dispatch("SALES_DOWNLOAD_EXCEL");
      if (this.$route.name === "claim")
        this.$store.dispatch("CLAIM_DOWNLOAD_EXCEL");
      if (this.$route.name === "endClaim")
        this.$store.dispatch("CLAIM_DOWNLOAD_EXCEL");
      if (this.$route.name === "purchasecalculate")
        this.$store.dispatch("PURCHASECAC_DOWNLOAD_EXCEL");
      if (this.$route.name === "salescalculate")
        this.$store.dispatch("SALESCAC_DOWNLOAD_EXCEL");
      if (this.$route.name === "purchaseVat")
        this.$store.dispatch("PURVAT_DOWNLOAD_EXCEL");
      if (this.$route.name === "salesVat")
        this.$store.dispatch("SALEVAT_DOWNLOAD_EXCEL");
    },
    excelPageCheck() {
      return this.allowExcelPages.includes(this.$route.name);
    },

    docKind(name) {
      return name === "order"
        ? "오더"
        : name === "release"
        ? "출고"
        : name === "manufacture"
        ? "가공"
        : name === "claim"
        ? "클레임"
        : name === "purchasecalculate"
        ? "매입 정산"
        : name === "salescalculate"
        ? "매출 정산"
        : name === "purchasedeadline"
        ? "매입 마감 취소"
        : name === "stock"
        ? "재고 마감"
        : name === "useStock"
        ? "재고 사용"
        : name === "deleteStock"
        ? "사용 재고 취소"
        : "매출 마감 취소";
    },

    initFilter() {
      this.$store.dispatch(
        "CALL_SEARCH_API",
        this.initSearchData(this.setParam),
      );
      this.initHeader();
    },

    initHeader() {
      this.header[this.routeName] = this.header[this.routeName].map((x) => {
        x.checked = false;
        return x;
      });
    },

    initSearchData(param) {
      const names = commitName[this.routeName];
      return {
        paramName: names.paramName,
        totalApiName: names.totalApiName,
        searchApiName: names.searchApiName,
        totalData: {
          param,
          commitName: names.totalCommitName,
        },
        searchData: {
          param,
          commitName: names.searchCommitName,
        },
      };
    },

    helpOpen() {
      window.open("/help", "_blank", "width:1024,height:768");
    },

    navClick(e) {
      this.$store.commit("setNavSelected", {
        title: "회사정보",
        value: "company",
      });
    },
    async getLogoImg() {
      if (this.logoImg._id) {
        try {
          const result = await downloadFile(
            this.$store.state.auth_company,
            this.logoImg._id,
          );

          if (result.status !== 200) throw `error: ${result.status}`;
          const downFile = new Blob([result.data]);

          this.preview = window.URL.createObjectURL(downFile);
        } catch (e) {
          console.log(e);
        }
      }
    },
    cacutionOpen() {
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "dialog_cacution",
      });
    },
    async changeReqeustForm(e) {
      if (this.$store.state.auth_grade >= 3) {
        const requestType = e === 1 ? "complete" : e === 2 ? "normal" : "";
        if (e === 1) {
          await this.$store.dispatch("GET_REQUEST_TYPE_LIST", requestType);
        }
        if (e === 2) {
          await this.$store.dispatch("GET_REQUEST_TYPE_LIST", requestType);
        }
        this.$store.commit("setRequestType", requestType);
      }
      if (this.$store.state.auth_grade <= 2) {
        const requestType = e === 1 ? "complete" : e === 0 ? "normal" : "";
        if (e === 0) {
          await this.$store.dispatch("GET_REQUEST_TYPE_LIST", requestType);
        }
        if (e === 1) {
          await this.$store.dispatch("GET_REQUEST_TYPE_LIST", requestType);
        }
        this.$store.commit("setRequestType", requestType);
      }
    },
  },
  sockets: {
    async approval(data) {
      if (this.$store.state.auth_company === data.company)
        this.setHeaderAlert();
    },
  },
  async created() {
    this.loadHistory();
    this.setHeaderAlert();
  },
};
